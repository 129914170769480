import * as React from 'react';
import { useWindowSize } from "@uidotdev/usehooks";
import {
    Box, Grid,
    Typography,
    Card,
    CardContent,
    Button,
    Switch,
    Stack,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
    useMediaQuery
} from "@mui/material";
import ESS_LOGO from "../../public/images/ESS-06.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from "@mui/system";
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import RecommendIcon from '@mui/icons-material/Recommend';
import axios from "axios";

export default function Plans() {
    const StyledCard = styled(Card)(({ theme, isPro }) => ({
        borderRadius: 20,
        height: "100%",
        backgroundColor: isPro ? "#212a33" : "#11151b",
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
            transform: "translateY(-10px)",
            boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)"
        }
    }));

    const [isYearly, setIsYearly] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const windowSize = useWindowSize();
   
    const checkOutWallet = async (price,plan,user) =>{
        const planSplit = plan.split(" ")[1]; 
        if(plan == "ESS Advance"){
            price = 0.2*5;
        }
        const endPoint = `https://escan-systems.com/api/pay/1/${planSplit}/${price}`;
        const data = { email:"mail.nabeelejaz@gmail.com" };
        try {
          const response = await axios.post(endPoint, data);
          //console.log(response);
          const newWindow = window.open(response.data.url, '_blank', 'noopener,noreferrer')
          if (newWindow) newWindow.opener = null

        } catch (error) {
         
        }

    }

    const plans = [

        {
            title: "ESS Basic",
            price: isYearly ? 100 : 100,
            features: [
                "Minimum Area 650 Ha",
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "Crop monitoring learning module"
            ],
            icon: <StarIcon style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }} sx={{ fontSize: 'inherit' }} />
        },
        {
            title: "ESS Advance",
            price: isYearly ? 0.20 + "/ Ha" : 0.20 + "/Ha",
            features: [
                "Minimum Area 5 Ha",
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "AI Analytics for VRA",
                "OpenAI Driven Decision Support",
            ],
            icon: <RecommendIcon style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }} sx={{ fontSize: 'inherit' }} />,
            isPro: true
        }
    ];

    return (

        <Grid container component="main" xs={12} style={{ backgroundColor: "#181f26" }}>


            <Box
                sx={{
                    minHeight: "100vh",
                    width: '100%',
                    py: 8,
                    px: { xs: 2, md: 6 }
                }}
            >


                <img src={ESS_LOGO} width={"200px"} />

                <Box id="2">

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center", flexDirection: 'column',
                            mb: 6
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="h5"
                            sx={{ fontWeight: "bold", color: "#fff", mt: 5, mb: 10, textAlign: 'center' }}
                        >
                            Your Plan has been expired, please subscribe to any of the below plan to continue to Monitor your Fields
                        </Typography>

                        <Stack
                            direction={{ xs: "column", md: "row", }}
                            sx={{mb:2}}
                            spacing={4}
                            justifyContent="center"
                            alignItems="stretch"
                        >
                            {plans.map((plan) => (
                                <Box
                                    key={plan.title}
                                    sx={{
                                        flex: 1,
                                        maxWidth: { xs: "100%", md: "350px" },cursor:'pointer'
                                    }}
                                >
                                    <StyledCard isPro={plan.isPro}>
                                        <CardContent
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                height: "100%",
                                                p: 4
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 2,
                                                    mb: 3
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        color: plan.isPro ? "#ffd700" : "#fff",
                                                        fontSize: "2rem"
                                                    }}
                                                >
                                                    {plan.icon}
                                                </Box>
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontWeight: "bold",
                                                        color: "#fff"
                                                    }}
                                                >
                                                    {plan.title}
                                                </Typography>
                                            </Box>

                                            <Typography
                                                variant="h4"
                                                sx={{ fontWeight: "bold", color: "#fff", mb: 3 }}
                                            >
                                                ${plan.price}
                                                <Typography
                                                    component="span"
                                                    sx={{ fontSize: "1rem", color: "#bbb" }}
                                                >
                                                    /{isYearly ? "year" : "month"}
                                                </Typography>
                                            </Typography>

                                            <List sx={{ flexGrow: 1, mb: 3 }}>
                                                {plan.features.map((feature) => (
                                                    <ListItem key={feature} sx={{ px: 0 }}>
                                                        <ListItemIcon>
                                                            <CheckIcon style={{ color: "#4caf50" }} />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={feature}
                                                            sx={{ color: "#fff" }}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>

                                            <Typography
                                                component="span"
                                                sx={{
                                                    fontSize: "1rem",
                                                    color: "#bbb",
                                                    display: "flex",
                                                    alignItems: "center",fontWeight: "bold", 
                                                    justifyContent: "space-between",
                                                }}
                                                onClick={()=>{
                                                    checkOutWallet(plan.price,plan.title,1)
                                                }}
                                            >
                                                Get it Now
                                                <Box
                                                    sx={{
                                                        background: '#fff',
                                                        borderRadius: '50%',
                                                        width: 40,
                                                        height: 40,
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly',
                                                        alignItems: 'center',
                                                        marginLeft: 2,
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <ArrowBackIcon style={{ color: "#000", transform: "rotate(180deg)" }} />
                                                </Box>
                                            </Typography>

                                             
                                        </CardContent>
                                    </StyledCard>
                                </Box>
                            ))}
                        </Stack>
                    </Box>



                </Box>
            </Box>




        </Grid>
    );
}

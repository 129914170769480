import * as React from 'react';
import { useWindowSize } from "@uidotdev/usehooks";
import {
    Box, Grid,
    Typography,
    Card,
    CardContent,
    Button,
    Switch,
    Stack,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
    useMediaQuery,
    Divider
} from "@mui/material";
import ESS_LOGO from "../../public/images/ESS-06.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from "@mui/system";
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import RecommendIcon from '@mui/icons-material/Recommend';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';

export default function Success() {
    const navigate = useNavigate();
    const StyledCard = styled(Card)(({ theme, isPro }) => ({
        borderRadius: 20,
        height: "100%",
        backgroundColor: isPro ? "#212a33" : "#11151b",
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
            transform: "translateY(-10px)",
            boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)"
        }
    }));

    const location = useLocation();



    const [isYearly, setIsYearly] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const windowSize = useWindowSize();
    const [status, setStatus] = React.useState([]);

    const SuccessIcon =
        `<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4695 0.232963C15.8241 0.561287 15.8454 1.1149 15.5171 1.46949L6.14206 11.5945C5.97228 11.7778 5.73221 11.8799 5.48237 11.8748C5.23253 11.8698 4.99677 11.7582 4.83452 11.5681L0.459523 6.44311C0.145767 6.07557 0.18937 5.52327 0.556912 5.20951C0.924454 4.89575 1.47676 4.93936 1.79051 5.3069L5.52658 9.68343L14.233 0.280522C14.5613 -0.0740672 15.1149 -0.0953599 15.4695 0.232963Z" fill="white"/>
    </svg>`;

    const ErrorIcon =
        `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25628 1.25628C1.59799 0.914573 2.15201 0.914573 2.49372 1.25628L8 6.76256L13.5063 1.25628C13.848 0.914573 14.402 0.914573 14.7437 1.25628C15.0854 1.59799 15.0854 2.15201 14.7437 2.49372L9.23744 8L14.7437 13.5063C15.0854 13.848 15.0854 14.402 14.7437 14.7437C14.402 15.0854 13.848 15.0854 13.5063 14.7437L8 9.23744L2.49372 14.7437C2.15201 15.0854 1.59799 15.0854 1.25628 14.7437C0.914573 14.402 0.914573 13.848 1.25628 13.5063L6.76256 8L1.25628 2.49372C0.914573 2.15201 0.914573 1.59799 1.25628 1.25628Z" fill="white"/>
    </svg>`;

    const InfoIcon =
        `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.5H4C2.61929 1.5 1.5 2.61929 1.5 4V10C1.5 11.3807 2.61929 12.5 4 12.5H10C11.3807 12.5 12.5 11.3807 12.5 10V4C12.5 2.61929 11.3807 1.5 10 1.5ZM4 0C1.79086 0 0 1.79086 0 4V10C0 12.2091 1.79086 14 4 14H10C12.2091 14 14 12.2091 14 10V4C14 1.79086 12.2091 0 10 0H4Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 7C5.25 6.58579 5.58579 6.25 6 6.25H7.25C7.66421 6.25 8 6.58579 8 7V10.5C8 10.9142 7.66421 11.25 7.25 11.25C6.83579 11.25 6.5 10.9142 6.5 10.5V7.75H6C5.58579 7.75 5.25 7.41421 5.25 7Z" fill="white"/>
      <path d="M5.75 4C5.75 3.31075 6.31075 2.75 7 2.75C7.68925 2.75 8.25 3.31075 8.25 4C8.25 4.68925 7.68925 5.25 7 5.25C6.31075 5.25 5.75 4.68925 5.75 4Z" fill="white"/>
    </svg>`;

    React.useEffect(() => {

        const fetchData = async () => {

            const queryParams = new URLSearchParams(location.search);
            const sessionId = queryParams.get('session_id');
            const endPoint = `https://escan-systems.com/api/pay/response`;
            const data = { sessionID: sessionId };
            try {
                const response = await axios.post(endPoint, data);
                setStatus(response.data.transaction);


            } catch (error) {

            }
        }

        fetchData()

    }, []);

    const plans = [

        {
            title: "ESS Basic",
            price: isYearly ? 100 : 100,
            features: [
                "Minimum Area 650 Ha",
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "Crop monitoring learning module"
            ],
            icon: <StarIcon style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }} sx={{ fontSize: 'inherit' }} />
        },
        {
            title: "ESS Advance",
            price: isYearly ? 0.20 + "/ Ha" : 0.20 + "/Ha",
            features: [
                "Minimum Area 5 Ha",
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "AI Analytics for VRA",
                "OpenAI Driven Decision Support",
            ],
            icon: <RecommendIcon style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }} sx={{ fontSize: 'inherit' }} />,
            isPro: true
        }
    ];

    return (

        <Grid container component="main" xs={12} style={{ backgroundColor: "#181f26" }}>


            <Box
                sx={{
                    minHeight: "100vh",
                    width: '100%',
                    py: 8,
                    px: { xs: 2, md: 6 }
                }}
            >


                <img src={ESS_LOGO} width={"200px"} />


                <Box
                    id="2"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '80vh', // Full viewport height
                        width: '100%' // Full width of the viewport
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: 'column',
                            mb: 6
                        }}
                    >
                        <Stack
                            direction={{ xs: "column", md: "row", }}
                            sx={{ mb: 2 }}
                            spacing={4}
                            justifyContent="center"
                            alignItems="stretch"
                        >
                            <Box
                                key={1}
                                sx={{
                                    flex: 1,
                                    maxWidth: { xs: "100%", md: "750px" },
                                    cursor: 'pointer'
                                }}
                            >
                                <StyledCard>
                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "100%",
                                            p: 4
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: 2,
                                                mb: 3,
                                                backgroundColor: status.payment_status == "paid" ? "#30B130" : "#DF1B41",
                                                height: "45px",
                                                width: "45px",
                                                borderRadius: "50%",
                                                margin: '10px auto'
                                            }}
                                        >
                                            {
                                                status.payment_status == "paid" ? <>


                                                    <svg width="20" height="20" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4695 0.232963C15.8241 0.561287 15.8454 1.1149 15.5171 1.46949L6.14206 11.5945C5.97228 11.7778 5.73221 11.8799 5.48237 11.8748C5.23253 11.8698 4.99677 11.7582 4.83452 11.5681L0.459523 6.44311C0.145767 6.07557 0.18937 5.52327 0.556912 5.20951C0.924454 4.89575 1.47676 4.93936 1.79051 5.3069L5.52658 9.68343L14.233 0.280522C14.5613 -0.0740672 15.1149 -0.0953599 15.4695 0.232963Z" fill="white" />
                                                    </svg>
                                                </> : <>
                                                    {
                                                        status.payment_status == "open" ? <>
                                                            <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.5H4C2.61929 1.5 1.5 2.61929 1.5 4V10C1.5 11.3807 2.61929 12.5 4 12.5H10C11.3807 12.5 12.5 11.3807 12.5 10V4C12.5 2.61929 11.3807 1.5 10 1.5ZM4 0C1.79086 0 0 1.79086 0 4V10C0 12.2091 1.79086 14 4 14H10C12.2091 14 14 12.2091 14 10V4C14 1.79086 12.2091 0 10 0H4Z" fill="white" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 7C5.25 6.58579 5.58579 6.25 6 6.25H7.25C7.66421 6.25 8 6.58579 8 7V10.5C8 10.9142 7.66421 11.25 7.25 11.25C6.83579 11.25 6.5 10.9142 6.5 10.5V7.75H6C5.58579 7.75 5.25 7.41421 5.25 7Z" fill="white" />
                                                                <path d="M5.75 4C5.75 3.31075 6.31075 2.75 7 2.75C7.68925 2.75 8.25 3.31075 8.25 4C8.25 4.68925 7.68925 5.25 7 5.25C6.31075 5.25 5.75 4.68925 5.75 4Z" fill="white" />
                                                            </svg>
                                                        </> : <>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25628 1.25628C1.59799 0.914573 2.15201 0.914573 2.49372 1.25628L8 6.76256L13.5063 1.25628C13.848 0.914573 14.402 0.914573 14.7437 1.25628C15.0854 1.59799 15.0854 2.15201 14.7437 2.49372L9.23744 8L14.7437 13.5063C15.0854 13.848 15.0854 14.402 14.7437 14.7437C14.402 15.0854 13.848 15.0854 13.5063 14.7437L8 9.23744L2.49372 14.7437C2.15201 15.0854 1.59799 15.0854 1.25628 14.7437C0.914573 14.402 0.914573 13.848 1.25628 13.5063L6.76256 8L1.25628 2.49372C0.914573 2.15201 0.914573 1.59799 1.25628 1.25628Z" fill="white" />
                                                            </svg>

                                                        </>
                                                    }
                                                </>
                                            }

                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: 'column',
                                                alignItems: "center",
                                                gap: 2,
                                                mb: 3
                                            }}
                                        >

                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontWeight: "bold",
                                                    color: "#fff"
                                                }}
                                            >
                                                {`Payment status: ${status.status}`}

                                            </Typography>

                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    fontWeight: "bold",
                                                    color: "#fff"
                                                }}
                                            >
                                                {`Invoice status: ${status.payment_status}`}

                                            </Typography>

                                            <Box
                                                sx={{
                                                    height: '1px',
                                                    width: '80%',
                                                    margin: '10px',
                                                    background: '#fff',
                                                    backgroundColor: '#fff'
                                                }}
                                            />
                                            {
                                                status.payment_status == "paid" ? <>
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontWeight: "bold",
                                                            color: "#fff",
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        {`Hello: ${status.customer_name}, Thank you for subscribing.`}
                                                    </Typography>

                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontWeight: "bold",
                                                            color: "#fff",
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        {`Your payment of $${status.amount_subtotal} has been processed${status.planSub ? " & Plan has been subscribed." : ""}`}

                                                    </Typography>
                                                    {status.planSub ? <>

                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                fontSize: "1.2rem",
                                                                color: "#000", borderRadius: '10px',
                                                                display: "flex",
                                                                alignItems: "center", fontWeight: "bold",
                                                                justifyContent: "space-between",
                                                                backgroundColor: '#fff', padding: 1
                                                            }}
                                                            onClick={() => { navigate("/monitor") }}

                                                        >
                                                            Proceed to Monitoring Dashboard

                                                            <Box
                                                                sx={{
                                                                    background: '#fff',
                                                                    borderRadius: '10%',
                                                                    width: 30,
                                                                    height: 30,
                                                                    display: 'flex',
                                                                    justifyContent: 'space-evenly',
                                                                    alignItems: 'center',
                                                                    marginLeft: 2,
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                <ArrowBackIcon style={{ color: "#000", transform: "rotate(180deg)" }} />

                                                            </Box>
                                                        </Typography>

                                                    </> : <></>
                                                    }



                                                </> : <></>
                                            }





                                        </Box>
                                    </CardContent>
                                </StyledCard>
                            </Box>
                        </Stack>
                    </Box>
                </Box>



            </Box>




        </Grid>
    );
}

import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("token", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const loginAuth = async (data) => {
    console.log("DATA",data)
    const token = data.token.replace(/['"]+/g, '')
    setUser(token);
    if(data.status == 1 || data.status == "1"){

      navigate("/plan-subscribe");
      
    }else{
 
      //navigate("/plan-subscribe");
      
      navigate("/monitor");

    }
   
  };

  // call this function to sign out logged in user
  const logoutAuth = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      loginAuth: loginAuth,
      logoutAuth: logoutAuth,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
import Toolbar from "@mui/material/Toolbar";
import { Box, Typography, Grid, CardContent, Card } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import * as React from 'react';
import { 
    Button,
    Switch,
    Stack,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
    useMediaQuery
  } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import FinalCard from "./FinalCard";
import SmallerCard from "./SmallerCard";
import CustomCarousel from "./CustomCarousel";
import PixelCard from "./PixelCard";
import service_1 from "../../public/images/service_1.png";
import service_2 from "../../public/images/service_2.png";
import service_3 from "../../public/images/service_3.png";
import Goals_IMG from "../../public/images/Sustainable-Development-Goals.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from "@mui/system";
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import RecommendIcon from '@mui/icons-material/Recommend'; 
import { useState } from "react";
import { ASSET_IMAGE } from "../utils/constants";
import { useNavigate } from "react-router-dom";


export default function PlanComponent() {
    const navigate = useNavigate();
    const Goals1 = "SGD/1.jpg";
    const Goals2 = "SGD/2.jpg";
    const Goals3 = "SGD/3.jpg";
    const Goals4 = "SGD/6.jpg";
    const Goals5 = "SGD/7.jpg";
    const Goals6 = "SGD/11.jpg";
    const Goals7 = "SGD/13.jpg";
    const Goals8 = "SGD/14.jpg";
    const Goals9 = "SGD/16.jpg";
    const goals = [
        { id: 1, color: '#e5243b', text: 'No Poverty', desc: 'End poverty in all its forms everywhere.', img: ASSET_IMAGE + "/" + Goals1 },
        { id: 2, color: '#dda63a', text: 'Zero Hunger', desc: "End hunger, achieve food security and improved nutrition and promote sustainable agriculture.", img: ASSET_IMAGE + "/" + Goals2 },
        { id: 3, color: '#4c9f38', text: 'Good Health and Well-being', desc: "Ensure healthy lives and promote well-being for all at all ages.", img: ASSET_IMAGE + "/" + Goals3 },
        { id: 6, color: '#26bde2', text: 'Clean Water and Sanitation', desc: "Ensure availability and sustainable management of water and sanitation for all.", img: ASSET_IMAGE + "/" + Goals4 },
        { id: 7, color: '#fcc30b', text: 'Affordable and Clean Energy', desc: "Ensure access to affordable, reliable, sustainable and modern energy for all.", img: ASSET_IMAGE + "/" + Goals5 },
        { id: 11, color: '#f89d2a', text: 'Sustainable Cities and Communities', desc: "Make cities and human settlements inclusive, safe, resilient and sustainable.", img: ASSET_IMAGE + "/" + Goals6 },
        { id: 13, color: '#3f7e44', text: 'Climate Action', desc: "Take urgent action to combat climate change and its impacts.", img: ASSET_IMAGE + "/" + Goals7 },
        { id: 14, color: '#0a97d9', text: 'Life Below Water', desc: "Conserve and sustainably use the oceans, seas and marine resources for sustainable development.", img: ASSET_IMAGE + "/" + Goals8 },
        { id: 16, color: '#00689d', text: 'Peace, Justice and Strong Institutions', desc: "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.", img: ASSET_IMAGE + "/" + Goals9 },
    ];
    const [hoveredGoal, setHoveredGoal] = useState(null);

    const StyledCard = styled(Card)(({ theme, isPro }) => ({
        borderRadius: 20,
        height: "100%",
        backgroundColor: isPro ? "#212a33" : "#11151b",
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
            transform: "translateY(-10px)",
            boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)"
        }
    }));


    const [isYearly, setIsYearly] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const windowSize = useWindowSize();
    const plans = [

        {
            title: "ESS Basic",
            price: isYearly ? 100 : 100,
            features: [
                "Minimum Area 650 Ha",
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "Crop monitoring learning module"
            ],
            icon: <StarIcon style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }} sx={{ fontSize: 'inherit' }} />
        },
        {
            title: "ESS Advance",
            price: isYearly ? 0.20 + "/ Ha" : 0.20 + "/Ha",
            features: [
                "Satellite based Crop Indicators",
                "Farm Weather with 14 days forecast",
                "Crop phenology",
                "Crop growth",
                "AI Analytics for VRA",
                "OpenAI Driven Decision Support",
            ],
            icon: <RecommendIcon style={{ cursor: 'pointer', fontSize: 'inherit', marginBottom: 8 }} sx={{ fontSize: 'inherit' }} />,
            isPro: true
        }
    ];

    return (
        <div id="sdg">
            <Grid container component="main">
 
                <Grid item xs={12} style={{ backgroundColor: "#181f26" }}>
                    <Box
                        style={{
                            position: "relative",
                            borderRadius: "50px",
                            margin: "0 auto",
                            marginTop: "40px",
                            marginBottom: "40px",
                            width:
                                windowSize.width < 992
                                    ? "95%"
                                    : windowSize.width < 1025
                                        ? "85%"
                                        : windowSize.width < 1960
                                            ? "65%"
                                            : "50%",
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="div"
                            color={"white"}
                            style={{ fontWeight: "bold", color: "#f27e30", marginBottom: 10 }}
                        >
                            <strong>Monitoring Plans</strong>
                        </Typography>
                        <Typography
                            variant="body1"
                            component="div"
                            textAlign={"justify"}
                            mb={5}
                            style={{ color: "rgb(193, 207, 219)" }}
                        >
                            Get the right plan for your needs at unbeatable promotional prices
                        </Typography>
                        

                        <Box>
                            <Stack
                                direction={{ xs: "column", md: "row" }}
                                spacing={4}
                                justifyContent="center"
                                alignItems="stretch"
                            >
                                {plans.map((plan) => (
                                    <Box
                                        key={plan.title}
                                        sx={{
                                            flex: 1,
                                            maxWidth: { xs: "100%", md: "350px" }
                                        }}
                                    >
                                        <StyledCard isPro={plan.isPro}>
                                            <CardContent
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    height: "100%",
                                                    p: 4
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 2,
                                                        mb: 3
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            color: plan.isPro ? "#ffd700" : "#fff",
                                                            fontSize: "2rem"
                                                        }}
                                                    >
                                                        {plan.icon}
                                                    </Box>
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontWeight: "bold",
                                                            color: "#fff"
                                                        }}
                                                    >
                                                        {plan.title}
                                                    </Typography>
                                                </Box>

                                                <Typography
                                                    variant="h5"
                                                    sx={{ fontWeight: "bold", color: "#fff", mb: 1 }}
                                                >
                                                    ${plan.price}
                                                    <Typography
                                                        component="span"
                                                        sx={{ fontSize: "1rem", color: "#bbb" }}
                                                    >
                                                        /{isYearly ? "year" : "month"}
                                                    </Typography>
                                                </Typography>

                                                <List sx={{ flexGrow: 1, mb: 3 }}>
                                                    {plan.features.map((feature) => (
                                                        <ListItem key={feature} sx={{ px: 0 }}>
                                                            <ListItemIcon>
                                                                <CheckIcon style={{ color: "#4caf50" }} />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={feature}
                                                                sx={{ color: "#fff" }}
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>

                                                <Typography
                                                    component="span"
                                                    sx={{
                                                        fontSize: "1rem",
                                                        color: "#bbb",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        fontWeight: "bold",
                                                    }}
                                                    onClick={()=>{navigate("/registration")}}
                                                >
                                                    Get Free Trial Now
                                                    <Box
                                                        sx={{
                                                            background: '#fff',
                                                            borderRadius: '50%',
                                                            width: 40,
                                                            height: 40,
                                                            display: 'flex',
                                                            justifyContent: 'space-evenly',
                                                            alignItems: 'center',
                                                            marginLeft: 2,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <ArrowBackIcon style={{ color: "#000", transform: "rotate(180deg)" }} />
                                                    </Box>
                                                </Typography>

                                            </CardContent>
                                        </StyledCard>
                                    </Box>
                                ))}
                            </Stack>

                            <Typography
                            variant="body1"
                            component="div"
                            textAlign={"justify"}
                            
                            mt={4}
                            style={{ color: "rgb(193, 207, 219)"}}
                        >

                            <CheckIcon style={{ color: "#4caf50",marginRight:20,marginBottom:5 }} />

                            For B2B / Customized Solutions with Special Care Plan of ESS contact us at email contact@escan-systems.com

                        </Typography>

                        <Typography
                            variant="body1"
                            component="div"
                            textAlign={"justify"}
                            
                            mt={2}
                            style={{ color: "rgb(193, 207, 219)"}}
                        >

                            <CheckIcon style={{ color: "#4caf50",marginRight:20,marginBottom:5 }} />

                            For Social Responsibility Package, Complementary in collaboration with Not for 
                            Profit Organizations contact us at email contact@escan-systems.com

                        </Typography>

                        </Box>


                    </Box>
                </Grid>


            </Grid>
        </div>
    );
}
  